import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import common from './common/common'

//  第一种 方式  挂原型链 引入 api 接口
import api from '@/api/api'
Vue.prototype.$api = api
Vue.prototype.$common  = common
//  第二种 方式  插件形式 引入 api 接口
// import api from  '@/api';
// Vue.use(api)


Vue.use(ElementUI);
Vue.use(Vant);
Vue.use(VueRouter)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

