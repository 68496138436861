/* 
    湖科档案管理系统H5端   学生文档查询模块 API
*/

import request from '@/api/request'

/**学生档案
 * 数据导入
 * @param {} data 
 * @returns 
 */
export const importData = (data) => {
    return request({
        url: '/system/search/importData',
        method: 'POST',
        params: data
    })
}

/**学生档案
 * 上传文件
 * @param {} data 
 * @returns 
 */
export const upload = (data) => {
    return request({
        url: '/system/search/upload',
        method: 'POST',
        params: data
    })
}

/**学生档案
 * 单个删除
 * @param {id} data 
 * @returns 
 */
export const remove = (data) => {
    return request({
        url: '/system/search/remove/{id}',
        method: 'POST',
        params: data
    })
}

/**学生档案
 * 批量删除
 * @param {} data 
 * @returns 
 */
export const batchRemove = (data) => {
    return request({
        url: '/system/search/batchRemove',
        method: 'POST',
        params: data
    })
}

/**学生档案
 * 添加
 * @param {} data 
 * @returns 
 */
export const add_save = (data) => {
    return request({
        url: '/system/search/add_save',
        method: 'POST',
        data: data
    })
}

/**学生档案
 * 数据导入档案列表
 * @param {?pageSize=10&pageNum=1&searchValue=39&searchValue=&orderByColumn=oper_time&orderByColumn=data_create_time&isAsc=desc&deptId=&parentId=} data 
 * @returns 
 */
export const searchList = (data) => {
    return request({
        url: '/system/search/list',
        method: 'GET',
        params: data
    })
}

/**学生档案
 * 数据导入档案列表
 * @param {?pageSize=10&pageNum=1&orderByColumn=data_create_time&isAsc=desc&student_number=&name=&data_create_time=} data 
 * @returns 
 */
export const countList = (data) => {
    return request({
        url: '/system/count/list',
        method: 'GET',
        params: data
    })
}

/**
 * 毕业生档案去向查询
 * @param {?name=test&student_number=1} data 
 * @returns 
 */
export const countSearch = (data) => {
    return request({
        url: `/system/count/search`,
        method: 'POST',
        params: data
    })
}