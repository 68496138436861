import Vue from 'vue'
import axios from 'axios'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import usingDetails from '@/views/operLog/usingDetails'
import evaluate from '@/views/usingRegistration/evaluate'
const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'index',
      component: () => import('@/views/index')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/login')
    },
    // 在线咨询
    {
      path: '/lquiryCounsel',
      name: 'lquiryCounsel',
      component: () => import('@/views/onlineCounsel/lquiryCounsel')
    },
    {
      path: '/lquiryCounsel/submitCounsel',
      name: 'submitCounsel',
      component: () => import('@/views/onlineCounsel/submitCounsel')
    },
    // 查询记录
    {
      path: '/queryRecords',
      name: 'queryRecords',
      component: () => import('@/views/queryRecords/queryRecords')
    },
    // 问题答疑
    {
      path: '/questionView',
      name: 'questionView',
      component: () => import('@/views/questionAnswer/questionView')
    },
    {
      path: '/questionView/replyQuestion',
      name: 'replyQuestion',
      component: () => import('@/views/questionAnswer/replyQuestion')
    },
    // 角色管理
    {
      path: '/roleManage',
      name: 'roleManage',
      component: () => import('@/views/roleManage/roleManage'),
    },
    {
      path: '/roleManage/roleAdd',
      name: 'roleAdd',
      component: () => import('@/views/roleManage/roleAdd')
    },
    {
      path: '/roleManage/roleEdit',
      name: 'roleEdit',
      component: () => import('@/views/roleManage/roleEdit')
    },
    // 用户管理
    {
      path: '/userManage',
      name: 'userManage',
      component: () => import('@/views/userManage/userManage')
    },
    {
      path: '/userManage/userAdd',
      name: 'userAdd',
      component: () => import('@/views/userManage/userAdd')
    },
    {
      path: '/userManage/userEdit',
      name: 'userEdit',
      component: () => import('@/views/userManage/userEdit')
    },
    {
      path: '/resetPwd',
      name: 'resetPwd',
      component: () => import('@/views/userManage/resetPwd')
    },
    {
      path: '/documentSearch',
      name: 'documentSearch',
      component: () => import('@/views/userManage/documentSearch')
    },
    {
      path: '/forgetpwd',
      name: 'forgetpwd',
      component: () => import('@/views/forgetpwd')
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/register')
    },
    {
      path: '/myApplication',
      name: 'myApplication',
      component: () => import('@/views/usingRegistration/myApplication')
    },
    {
      path: '/useExamine',
      name: 'useExamine',
      component: () => import('@/views/usingRegistration/useExamine')
    },
    {
      path: '/useMaterial',
      name: 'useMaterial',
      component: () => import('@/views/usingRegistration/useMaterial')
    },
    {
      path: '/useStudent',
      name: 'useStudent',
      component: () => import('@/views/usingRegistration/useStudent')
    },

    {
      path: '/operLog',
      name: 'operLog',
      component: () => import('@/views/operLog/operLog')
    },
    {
      path: '/userinfo',
      name: 'userinfo',
      component: () => import('@/views/userinfo')
    },
    {
      path: '/message',
      name: 'message/list',
      component: () => import('@/views/message/list')
    },
    {
      path: '/message/detail',
      name: 'message/detail',
      component: () => import('@/views/message/detail')
    },
    {
      path: '/data',
      name: 'data/list',
      component: () => import('@/views/data/list')
    },
    {
      path: '/data/upload',
      name: '/data/upload',
      component: () => import('@/views/data/upload')
    },
    {
      path: '/data/add',
      name: '/data/add',
      component: () => import('@/views/data/add')
    },
    {
      path: '/data/list',
      name: '/data/list',
      component: () => import('@/views/data/list')
    },
    {
      path: '/operLog/examineDetail',
      name: 'operLog/examineDetail',
      component: () => import('@/views/operLog/examineDetail')
    },
    {
      path: '/data/edit',
      name: '/data/edit',
      component: () => import('@/views/data/edit')
    },
    {
      path: '/operLog/usingDetails',
      name: '/operLog/usingDetails',
      component: usingDetails
    },
    {
      path: '/bindUserInfo',
      name: 'bindUserInfo',
      component: () => import('@/views/bindUserInfo')
    },
    {
      path: '/usingRegistration/evaluate',
      name: '/usingRegistration/evaluate',
      component: evaluate
    },
    {
      path: '/auth',
      name: 'auth',
      component: () => import('@/views/auth')
    },
  ]
})

// 页面白名单
const whiteList = [
  'login', 'register', 'forgetpwd', 'documentSearch', 'login2GetUser', 'auth', 'bindUserInfo'
]


router.beforeEach((to, from, next) => {

  if (!whiteList.includes(to.name)) {
    let currentUser = localStorage.getItem("User")
    if (!currentUser) {
      console.log(111)
      next({
        replace: true,
        path: '/login'
      })
    } else {
      next()
    }
  } else {
    next()
  }

})


export default router
