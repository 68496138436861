<template>
    <div>
        <van-row class="t">
            <van-col>评分:</van-col>
            <van-col><van-rate v-model="rateScore" :size="25" color="#ffd21e" void-icon="star"
                    void-color="#eee" /></van-col>
            <van-col class="d">
                <div v-if="rateScore >= 5">
                    <p>非常满意</p>
                </div>
                <div v-else-if="rateScore >= 4">
                    <p>满意</p>
                </div>
                <div v-else-if="rateScore >= 3">
                    <p>中等</p>
                </div>
                <div v-else-if="rateScore >= 2">
                    <p>不满意</p>
                </div>
                <div v-else>
                    <p>很不满意</p>
                </div>
            </van-col>
        </van-row>
        <van-field type="textarea" v-model="reason" label="原因" placeholder="请留下您的宝贵意见" style="font-size: 4vw;" row=3 />
        <div class="tr">
            <van-button class="btn" @click="submit" v-if="state == 1">提交</van-button>
            <van-button class="btn1" @click="recover">返回</van-button>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            rateScore: 5,
            reason: '',
            state: 1,
            dataId: this.$route.query.id
        }
    },
    methods: {
        submit() {
            // const tr={
            //     'rateScore':this.rateScore,
            //     'reason':this.reason,
            //     dataId: 449,
            // }
            // const tr=`dataId=449&rateScore=${this.rateScore}&reason=${this.reason}`
            const obj = {
                dataId: this.dataId,
                rateScore: this.rateScore,
                reason: this.reason
            }
            this.$api.homePage.saveEvaluate(obj).then((res) => {
                console.log(res);
                this.$router.go(-1);
                // let temp = {}
                // temp = res.data.rateModel
                // this.state=temp.state
            })

        },
        recover() {
            this.$router.go(-1);

        },
        getList() {
            this.$api.homePage.getEvaluate(this.dataId).then((res) => {
                console.log(res)
                const temp = res.data.rateModel
                this.state = temp.state
                this.reason = temp.reason
                this.rateScore = temp.rateScore
            })
        }
    },
    mounted() {
        this.getList();
    },
}
</script>

<style lang="scss"  scoped>
.t {
    width: 100vw;
    height: 15vw;
    margin-top: 10vw;
    left: 5vw;
    display: flex;
    justify-content: space-around;

    .d {
        margin-top: -3vw;
    }
}

.tr {
    width: 100vw;
    border: 0 solid;
}

.btn {
    width: 30vw;
    height: 8vw;
    border-radius: 10vw;
    position: absolute;
    left: 14vw;
    background-color: rgb(24, 126, 22);
    color: white;
}

.btn1 {
    width: 30vw;
    height: 8vw;
    border-radius: 10vw;
    position: absolute;
    right: 14vw;
    background-color: rgb(189, 59, 15);
    color: white;
}
</style>