/* 
    湖科档案管理系统H5端   问题管理模块 API
*/

import request from '@/api/request'

/**在线咨询
 * 咨询列表
 * @param {?pageSize=5&pageNum=1&orderByColumn=oper_time&isAsc=desc} data 
 * @returns 
 */
export const saveProblemInfo = (data) => {
    return request({
        // 确实是后面有一个/
        url: '/system/problem/saveProblemInfo',
        method: 'post',
        data: data
    })
}

/**在线咨询
 * 提交咨询
 * @param {?problemName=%E8%BF%99%E6%98%AF%E5%92%A8%E8%AF%A2%E5%86%85%E5%AE%B9} data 
 * @returns 
 */
export const auditProblem = (data) => {
    return request({
        url: '/system/problem/auditProblem',
        method: 'POST',
        data: data
    })
}

/**业务答疑
 * 搜索列表
 * @param {?pageSize=10&pageNum=1&orderByColumn=oper_time&isAsc=desc&from_date=&to_date=&version=1} data 
 * @returns 
 */
export const saveProblemDetailInfo = (data) => {
    return request({
        // 确实是后面有一个/
        url: '/system/problem/saveProblemDetailInfo/',
        method: 'POST',
        data: data
    })
}

/**业务答疑
 * 回复信息
 * @param {?hidden=&ids=&problemName=&problemContent=} data 
 * @returns 
 * ids 671
 */
export const checkProblemType = (data) => {
    return request({
        url: '/system/problem/checkProblemType/{ids}',
        method: 'POST',
        params: data
    })
}


/**业务答疑
 * 回复信息
 * @param {hidden=0&ids=17&problemName=%E6%9F%A5%E8%AF%A2&problemContent=ttt} data 
 * @returns 
 * ids 671
 */
export const replyProblem = (num, d) => {
    return request({
        url: `/system/problem/replyProblem/${num}`,
        method: 'POST',
        data: d
    })
}

