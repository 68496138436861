 // 全局公共函数   this.common 调用

export default {
    setCookie (name,value,day){
        let date = new Date();
        date.setDate(date.getDate()+day)
        document.cookie = name+'='+value+';expires='+date
    },
    getCookie(name){
        let reg = RegExp(name+'=([^;]+)');
        let arr= document.cookie.match(reg);
        if(arr){
            return arr[1];
        }else{
            return ''
        }
    },
    // 删除
    delCookie(name){
        setCookie(name,null,-1);
    }
}