/* 
    湖科档案管理系统H5端   应用管理模块 API
*/

import request from '@/api/request'

/**搜索列表
 * 
 * @param {?pageSize=10&pageNum=1&searchValue=%E5%BC%A0%E4%B8%89} data 
 * @returns 
 */
export const list = (data) => {
    return request({
        url: '/appMng/message/list',
        method: 'GET',
        params: data
    })
}

/**标记已读
 * 
 * @param {} data 
 * @returns 
 */
export const updateReadStatus = (id) => {
    return request({
        // 需要路径后面传参
        url: '/appMng/message/updateReadStatus/'+id,
        method: 'GET',
        // data: data
    })
}

/**批量删除
 * 
 * @param {?ids[]=9841&ids[]=9756} data 
 * @returns 
 */
export const batchRemove = (data) => {
    return request({
        url: '/appMng/message/batchRemove',
        method: 'POST',
        params: data
    })
}

/**未读数统计
 * 
 * @param {?ids[]=9841&ids[]=9756} data 
 * @returns 
 */
export const getMessageNum = () => {
    return request({
        url: '/appMng/message/getMessageNum',
        method: 'GET'
    })
}






