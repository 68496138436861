/* 
    湖科档案管理系统H5端   首页模块 API
*/
import request from '@/api/request'

/**
 * 利用登记
 * @param {?usingUser=%E7%AE%A1%E7%90%86%E5%91%98&appType=1&usingAttribute=2&unit=%E6%B9%96%E5%8C%97%E7%A7%91%E6%8A%80%E5%AD%A6%E9%99%A2&usingType=1&addressStu=%E6%B9%96%E5%8C%97&turnMajor=1&adultStu=0&recipientUser=%E5%BC%A0%E4%B8%89&recipientPhone=15625645896&recipientAddress=%E6%B9%96%E5%8C%97%E7%9C%81%E5%92%B8%E5%AE%81%E5%B8%82%E6%B9%96%E5%8C%97%E7%A7%91%E6%8A%80%E5%AD%A6%E9%99%A2&useContent1=1&useContent2=0&useContent3=0&useContent4=0&useContent5=0&remark=%E6%A1%A3%E6%A1%88%E6%8A%95%E9%80%92%E5%88%B0%E6%B9%96%E5%8C%97%E7%A7%91%E6%8A%80%E5%AD%A6%E9%99%A2&idCardPhoto1=2862&idCardPhoto2=2861&idCardPhoto3=2866} data 
 * @returns 
 */
export const save = (data) => {
    return request({
        url: '/school/usingRegistration/save',
        method: 'POST',
        params: data
    })
}

/**
 * 个人信息
 * @param {?userId=671&userName=%E5%BC%A0%E4%B8%891&email=2968712475@qq.com&emailValidateCode=132165&remark=%E8%BF%99%E6%98%AF%E5%A4%87%E6%B3%A8%E4%BF%A1%E6%81%AF} data 
 * @returns 
 */
export const resetUserInfo = (data) => {
    return request({
        url: '/system/user/resetUserInfo',
        method: 'POST',
        params: data
    })
}

/**
 * 我的申请_搜索列表
 * @param {?pageSize=10&pageNum=1&searchValue=39&orderByColumn=oper_time&isAsc=desc&from_date=2023-05-20%2000:00:00&to_date=2023-06-16%2023:59:59} data 
 * @returns 
 */
export const list1 = (data) => {
    return request({
        url: '/school/usingRegistration/list/1',
        method: 'POST',
        data: data
    })
}

/**
 * 利用审核搜索列表
 * @param {?pageSize=10&pageNum=1&searchValue=39&orderByColumn=oper_time&isAsc=desc&from_date=2023-05-20%2000:00:00&to_date=2023-06-16%2023:59:59} data 
 * @returns 
 */
export const list2 = (data) => {
    return request({
        url: '/school/usingRegistration/list/2',
        method: 'POST',
        data: data
    })
}

/**
 * 获取首页图标文件
 * @param {} data 
 * @returns 
 */
export const img = (data) => {
    return request({
        url: '/img/icon_1register.svg',
        method: 'GET',
        data: data
    })
}

/**
 * 首页信息，菜单和用户
 * @param data 
 * @returns 
 */
export const indexApp = (data) => {
    return request({
        url: '/index_app',
        method: 'GET',
        data: data
    })
}
/**
 * 首页信息，利用登记审核同意
 * @param data 
 * @returns 
 */
export const examine = (data) => {
    return request({
        url: '/school/usingRegistration/auditInfo/1',
        method: 'POST',
        data: data
    })
}
/**
 * 首页信息，利用登记审核拒绝
 * @param data 
 * @returns 
 */
export const modify = (data) => {
    return request({
        url: '/school/usingRegistration/auditInfo/2',
        method: 'POST',
        data: data
    })
}
/**
 * 首页信息，利用登记详情
 * @param data 
 * @returns 
 */
export const detail = (id) => {
    return request({
        url: '/school/usingRegistration/audit/' + id + '/data',
        method: 'GET',
    })
}
/**
 * 首页信息，我的申请服务评价
 * @param data 
 * @returns 
 */
export const saveEvaluate = (data) => {
    return request({
        url: `/school/rate/save`,
        method: 'POST',
        params: data
    })
}

export const getEvaluate = (data) => {
    return request({
        url: `/school/rate/add_app/${data}`,
        method: 'POST',
        
    })
}