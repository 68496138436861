/* 
    湖科档案管理系统H5端   系统管理模块 API
*/
import request from '@/api/request'

/**
 * 用户管理...13行
 * 角色管理...133行
 * 操作日志...215行
 */

export const editApp =  (id)=>{
    
    return request({
        url: '/system/search/edit_app/'+id,
        method: 'GET',
    })
}


/**用户管理
 * 新增用户和编辑用户都是这个,本质上就是保持一个新的
 * @param {loginName=%E6%B5%8B%E8%AF%95&userName=123&password=f0ad2c9f80df37c4&email=434412311%40qq.com&phonenumber=15872101231&sex=1&status=0&roleIds=2%2C3%2C4%2C5%2C6%2C11&postIds=&idCardPhoto=4101&idCardNumber=42102321231230711} data 
 * @returns 
 */
export const userSave = (data) => {
    return request({
        url: '/system/user/save',
        method: 'POST',
        data: data
    })
}

/**用户管理
 * 用户名唯一性校验
 * @param {?loginName=test&name=test} data 
 * @returns 
 */
export const checkLoginNameUnique = (data) => {
    return request({
        url: '/system/user/checkLoginNameUnique',
        method: 'POST',
        data: data
    })
}

/**用户管理
 * 手机号唯一性校验
 * @param {?phonenumber=15698546567&userId=15698546567} data 
 * @returns 
 */
export const checkPhoneUnique = (data) => {
    return request({
        url: '/system/user/checkPhoneUnique',
        method: 'POST',
        data: data
    })
}

/**用户管理
 * 邮箱唯一性校验
 * @param {?email=434418761@qq.com&name=434418761@qq.com} data 
 * @returns 
 */
export const checkEmailUnique = (data) => {
    return request({
        url: '/system/user/checkEmailUnique',
        method: 'POST',
        data: data
    })
}

/**用户管理
 * 照片上传
 * @param {file:(二进制文件)} data 
 * @returns 
 */
export const fileUpload = (data) => {
    return request({
        url: '/file/upload',
        method: 'POST',
        data: data
    })
}


/**用户管理
 * 单个删除
 * @param {} data 
 * @returns 
 */
export const userRemove = (data) => {
    return request({
        url: '/system/user/remove/{id}',
        method: 'POST',
        params: data
    })
}

/**用户管理
 * 批量删除
 * @param {ids[]: 741&ids[]: 731} data 
 * @returns 
 */
export const batchRemove = (data) => {
    return request({
        url: '/system/user/batchRemove',
        method: 'POST',
        data: data
    })
}

/**用户管理
 * 重置密码
 * @param {} data 
 * @path {userId}
 * @returns 
 */
export const resetPwdDefault = (data) => {
    return request({
        url: `/system/user/resetPwdDefault/${data}`,
        method: 'POST',
    })
}

/**用户管理
 * 搜索列表
 * @param {?pageSize=10&pageNum=1&searchValue=2&orderByColumn=create_time&isAsc=desc&deptId=&parentId=&_=1687051535765} data 
 * @path {userId}
 * @returns 
 */
export const userList = (data) => {
    return request({
        url: `/system/user/list?${data}`,
        method: 'GET',
        data: data
    })
}

/**用户管理
 * 编辑的初始化
 * @param {} data 
 * @path {userId}
 * @returns 
 */
export const editData = (data) => {
    return request({
        url: `/system/user/edit/${data}/data`,
        method: 'GET',
        data: data
    })
}


// 角色管理

/**角色管理
 * 新增角色,编辑角色都是这个
 * @param {?roleKey=%E5%88%98%E6%B5%A9%E4%B8%9Ctest&roleName=%E5%88%98%E6%B5%A9%E4%B8%9Ctest&roleSort=1&status=0&remark=test&menuIds=&audit=0} data 
 * @returns 
 */
export const rolesSave = (data) => {
    return request({
        url: '/system/role/save',
        method: 'POST',
        data: data
    })
}
export const dataSave = (data)=>{
    return request({
        url: '/system/search/save',
        method: 'POST',
        data: data
    })
}
export const usingRegistrationAudit = (id)=>{
    return request({
        url: '/school/usingRegistration/audit/'+id+'/data',
        method: 'GET'
    })
}
export const usingRegistrationmenu = (id)=>{
    return request({
        url: '/school/usingRegistration/menu/'+id+'/data',
        method: 'GET'
    })
}

/**角色管理
 * 角色唯一性验证
 * @param {?roleName=%E5%88%98%E6%B5%A9%E4%B8%9Ctest} data 
 * @returns 
 */
export const checkRoleNameUnique = (data) => {
    return request({
        url: '/system/role/checkRoleNameUnique',
        method: 'POST',
        params: data
    })
}

/**角色管理
 * 权限树
 * @param {?roleId=2} data 
 * @returns 
 */
export const roleMenuTreeData = (data) => {
    return request({
        url: '/system/menu/roleMenuTreeData' + "?" + data,
        method: 'GET',
        data: data
    })
}

/**角色管理
 * 单个删除
 * @param {?ids[]=2} data 
 * @returns 
 */
export const roleRemove = (data) => {
    return request({
        url: '/system/role/remove/{id}',
        method: 'POST',
        params: data
    })
    
}
export const menu = (id) => {
    return request({
        url: 'system/problem/menu/'+id+'/data',
        method: 'GET'
    })
}
export const reply = (id) => {
    return request({
        url: 'system/problem/reply/'+id+'/data',
        method: 'GET'
    })
}
export const Userinfo = () => {
    return request({
        url: '/notice/data',
        method: 'GET'
    })
}
/**角色管理
 * 批量删除
 * @param {?ids[]=2} data 
 * @returns 
 */
export const roleBatchRemove = (data) => {
    return request({
        url: '/system/role/batchRemove',
        method: 'POST',
        data: data
    })
}

/**角色管理
 * 批量删除
 * @param {?pageSize=10&pageNum=1&orderByColumn=role_sort&isAsc=asc&roleName=&status=} data 
 * @returns 
 */
export const roleList = (data) => {
    return request({
        url: '/system/role/list',
        method: 'POST',
        data: data
    })
}

// 操作日志

/**操作日志
 * 操作日志列表
 * @param {?searchValue=%E7%94%A8%E6%88%B7&pageSize=10&pageNum=1&orderByColumn=oper_time%20&isAsc=desc&status=-1&from_date=&to_date=} data 
 * @returns
 */
export const operlogList = (data) => {
    return request({
        url: '/monitor/operlog/list',
        method: 'GET',
        params: data
    })
}

/**操作日志
 * 删除
 * @param {?ids[]=3296} data 
 * @returns
 */
export const operlogBatchRemove = (data) => {
    return request({
        url: '/monitor/operlog/batchRemove',
        method: 'POST',
        params: data
    })
}