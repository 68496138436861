// 封装 axios 
import axios from "axios";
import router from '@/router'

const service = axios.create({
	baseURL: '/api',
	timeout: 5000
})


// 请求拦截器
service.interceptors.request.use(config => {
	// 在发送 请求 之前 可以传递一些 参数到 config 中 
	console.log('请求拦截器', config);
	return config
}, error => {
	return error
})


service.interceptors.response.use(
	response => {
		console.log(response);
		// 如果状态码是200，直接返回响应数据
		if (response.status === 200) {
			return response;
		}
		// 否则跳转到登录页面
		else {
			router.push('/login'); // 请根据你的项目实际情况进行跳转
		}
	},
	error => {
		console.log('error', error);
		if (error.response.status === 404) {
			router.replace('/login');
		}
		// 处理请求错误
		return console.log('错误');
	}
);

export default service