<template>
    <van-form>
        <van-field name="usingUser" v-model="usingUser" required label="利用人" readonly />

        <!-- 申请类型 -->
        <van-field readonly clickable name="picker" :value="showData" label="申请类型" placeholder="请选择" />
        <van-popup v-model="showPicker" position="bottom">
            <van-picker show-toolbar :columns="TypeColumns" @confirm="onConfirm" />
        </van-popup>
        <!-- 手机号码 处理逻辑还没做 -->

        <van-field required label="手机号码" type="tel" maxlength="11" v-model="phone" name="phone" placeholder="请输入手机号" disabled/>

        <!-- 投档信息 -->
        <div v-if="showData == '学生档案派遣'">
            <van-field required type="textarea" v-model="remark" name="remark" label="档案投递信息" placeholder="请输入投档信息" disabled/>
        </div>
        <!-- 学生档案派遣 -->
        <div v-else>
            <van-field name="usingAttribute" required readonly clickable label="利用属性" :value="showData1" placeholder="请选择"
                       @click="showPicker1 = true" disabled/>
            <van-popup v-model="showPicker1" round position="bottom">
                <van-picker show-toolbar :columns="columns1" @cancel="showPicker1 = false" @confirm="onConfirm1" disabled/>
            </van-popup>

            <van-field required label="单位" v-model="unit" placeholder="请输入单位信息,没有填写个人" disabled/>

            <van-field v-model="addressStu" label="生源地" placeholder="湖北" disabled />

            <van-field name="usingType" required label="利用方式">
                <template #input>
                    <van-radio-group v-model="usingType" direction="horizontal" disabled>
                        <van-radio name="1">电子档案证明</van-radio>
                        <van-radio name="2">纸质档案证明</van-radio>
                    </van-radio-group>
                </template>
            </van-field>
            <div v-if="usingType == 2">
                <van-field required label="收件人" v-model="recipientUser" placeholder="请填写姓名" disabled/>
                <van-field required label="手机号码" :rules="[{ validator, message: '请输入正确的手机号' }]" v-model="recipientPhone"
                           placeholder="请填写收件号码" disabled/>
                <van-field required label="收件地址" v-model="recipientAddress" placeholder="请填写收件地址" disabled/>
            </div>
            <van-field name="turnMajor" label="是否转专业">
                <template #input>
                    <van-radio-group v-model="turnMajor" direction="horizontal" disabled>
                        <van-radio name="1">是</van-radio>
                        <van-radio name="0">否</van-radio>
                    </van-radio-group>
                </template>
            </van-field>

            <van-field name="adultStu" label="是否成教生">
                <template #input>
                    <van-radio-group v-model="adultStu" direction="horizontal" disabled>
                        <van-radio name="1">是</van-radio>
                        <van-radio name="0">否</van-radio>
                    </van-radio-group>
                </template>
            </van-field>

            <van-field name="useContent" required label="利用内容">
                <template #input>
                    <van-checkbox-group v-model="useContent" direction="horizontal" disabled>
                        <template v-for="(item, index) in contentColumns">
                            <van-checkbox class="ly" :name="item" shape="square" @click="toggle(item, index)">{{ item
                            }}</van-checkbox>
                        </template>
                    </van-checkbox-group>
                </template>
            </van-field>
            <div v-if="usingType == 1">
                <van-field type="textarea" name="remark" v-model="remark" label="备注" placeholder="请输入备注信息" disabled/>
            </div>
        </div>


        <van-field name="idCardPhoto1" label="身份证">
            <template #input>
                <van-uploader :max-count="1" v-model="idCardPhoto1" :after-read="idCardUpload"  disabled/>
            </template>
        </van-field>

        <van-field name="idCardPhoto2" required label="毕业证或学位证">
            <template #input>
                <van-uploader :max-count="1" v-model="idCardPhoto2" :after-read="xwUpload"   disabled/>
            </template>
        </van-field>
        <div v-if="nr == 1">
            <van-field name="idCardPhoto3" label="公函或其他证明文件">
                <template #input>
                    <van-uploader :max-count="1" v-model="idCardPhoto3" :after-read="ghUpload"   disabled/>
                </template>
            </van-field>
        </div>
        <div v-else-if="nr == 2">
            <van-field name="idCardPhoto3" label="公函或其他证明文件">
                <template #input>
                    <van-uploader :max-count="1" v-model="idCardPhoto3" :after-read="ghUpload"   disabled
                                  accept=".png, .jpg, .jpeg,.doc,.docs" />
                </template>

            </van-field>
            <van-field type="textarea" name="remark" v-model="remark" label="备注" placeholder="请输入备注信息" disabled/>
        </div>
        <div v-else="nr==3">
            <van-field required name="idCardPhoto3" label="调档公函">
                <template #input>
                    <van-uploader :max-count="1" v-model="idCardPhoto3" :after-read="ghUpload" disabled />
                </template>
            </van-field>
        </div>
        <div v-if="usingType == 1">
            <van-field required name="auditFileId" label="电子档案证明文件">
                <template #input>
                    <van-uploader :max-count="1" v-model="auditFileId" :after-read="ghUpload"  disabled />
                </template>
            </van-field>
        </div>
        <div v-else>
            <van-field required label="快递单号" v-model="receiptCoding" />
        </div>
        <van-field v-model="message" rows="1" autosize label="审核意见" type="textarea" />

        <div>
            <van-button class="btn3" @click="evaluate">服务评价</van-button>
            <van-button class="btn2" @click="recover">返回</van-button>
        </div>
    </van-form>
</template>

<script>
export default {
    data() {
        return {
            receiptCoding: '',
            auditFileId: [],
            message: '',
            auditOpinion: '',
            // 利用人
            usingUser: '',
            // 选择框 显示数据
            nr: 1,
            showData: '',
            appType: 0,
            showData1: '',
            usingAttribute: 0,
            unit: '',
            addressStu: '',
            TypeColumns: ["证明材料", "学生档案派遣"],
            showPicker: false,
            showPicker1: false,
            columns1: ['个人利用', "单位利用"],
            usingType: '1',
            turnMajor: '0',
            adultStu: '0',
            contentColumns: ["录取名单", "成绩单", "毕业生登记表", "学位授予名册", "其他"],
            useContent: [],
            useContent1: '',
            useContent2: '',
            useContent3: '',
            useContent4: '',
            useContent5: '',
            recipientUser: '',
            recipientPhone: '',
            recipientAddress: '',
            idCardImageId: 0,
            idCardPhoto1: [],
            idCardPhotoiD: 0,
            idCardPhoto2: [],
            xwUploadid: 0,
            idCardPhoto3: [],
            ghUPloadid: 0,
            phone: '',
            remark: '',
            id: '',
        }
    },
    created: function () {
        this.id = this.$route.query.id
        this.$api.homePage.detail(this.id).then(res => {
            let temp = {}
            temp = res.data.data.usingRegistrationBean
            console.log('333333',temp);
            this.code = temp.code
            this.usingUser = temp.usingUser;
            this.showData = this.TypeColumns[temp.appType == 1 ? 0 : 1]
            this.phone = temp.phone;
            this.showData1 = this.columns1[temp.usingAttribute == 1 ? 0 : 1]
            this.remark = temp.remark;
            this.unit = temp.unit;
            this.addressStu = temp.addressStu;
            if (temp.usingType) {
                this.usingType = temp.usingType.toString();
            }
            this.recipientUser = temp.recipientUser;
            this.recipientPhone = temp.recipientPhone;
            this.recipientAddress = temp.recipientAddress;
            this.turnMajor = temp.turnMajor.toString();
            this.adultStu = temp.adultStu.toString();
            if (temp.useContent1) {
                this.useContent.push("录取名单")
            }
            if (temp.useContent2) {
                this.useContent.push("成绩单")
            }
            if (temp.useContent3) {
                this.useContent.push("毕业生登记表")
            }
            if (temp.useContent4) {
                this.useContent.push("学位授予名册")
            }
            if (temp.useContent5) {
                this.useContent.push("其他")
            };
            if (temp.card[0]) {
                this.idCardPhoto1.push({ url: temp.card[0].message, isImage: true });
            }

            if (temp.graduation[0]) {
                this.idCardPhoto2.push({ url: temp.graduation[0].message, isImage: true });
            }

            if (temp.oauth[0]) {
                this.idCardPhoto3.push({ url: temp.oauth[0].message, isImage: true });
            }

            if (temp.auditFile[0]) {
                this.auditFileId.push({ url: temp.auditFile[0].message, isImage: true });
            }
            this.message = temp.auditOpinion;


        })
    },
    methods: {
        detail() {
            this.$api.homePage.detail(data).then((res) => {
                this.data = res.data.rows;
            })
        },
        recover() {
            this.$router.go(-1);

        },
        evaluate() {
            this.$router.push({
                path: "/usingRegistration/evaluate", query: {
                    id: this.id
                }
            });

        },
        submitForm() {
            // 三个状态都有逻辑

        },
        validator(value) {
            return /^1[3456789]\d{9}$/.test(value);
        },
        onConfirm(value, i) {
            this.showData = value;
            this.showPicker = false;
            this.appType = i == 0 ? 1 : 3;

        },
        onConfirm1(value) {
            this.showData1 = value;
            this.showPicker1 = false;
            this.usingAttribute = value == "个人利用" ? 1 : 2;
        },
        // 身份证上传
        idCardUpload(file) {

        },
        xwUpload(file) {
            console.log(file)

        },
        ghUpload(file) {

        },

        // 点击 处理  复选框  按钮
        toggle(item, index) {
            switch (index) {
                case 0:
                    this.useContent1 = this.useContent1 == item ? "" : item
                    break;
                case 1:
                    this.useContent2 = this.useContent2 == item ? "" : item
                    break;
                case 2:
                    this.useContent3 = this.useContent3 == item ? "" : item
                    break;
                case 3:
                    this.useContent4 = this.useContent4 == item ? "" : item
                    break;
                case 4:
                    this.useContent5 = this.useContent5 == item ? "" : item
                    break;
            }
        },

    },
    watch: {
        usingType: function (val) {
            if (val == 2) {
                this.nr = 2
            } else {
                this.nr = 1
            }
        },
        showData(val) {
            if (val == "学生档案派遣") {
                this.nr = 3
            }
        }
    }
}

</script>

<style lang="scss"  scoped>
.ly {
    margin: 5px;
}

.btn3 {
    width: 40vw;
    height: 8vw;
    border-radius: 10vw;
    position: absolute;
    left: 15vw;
    background-color: rgb(75, 75, 210);
    color: white;
}

.btn2 {
    width: 30vw;
    height: 8vw;
    border-radius: 10vw;
    position: absolute;
    right: 15vw;
    background-color: rgb(75, 75, 210);
    color: white;
}
</style>