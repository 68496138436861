/* 
    湖科档案管理系统H5端   基本功能模块 API
*/
import request from '@/api/request'

/**注册账号页面
 * 邮箱唯一性验证,以url参数
 * @param {?email=2968712478@qq.com&name=2968712478@qq.com} data 
 * @returns 
 */
export const checkEmailUnique = (data) => {
    return request({
        url: '/system/user/checkEmailUnique',
        method: 'POST',
        params: data
    })
}

/**注册账号页面
 * 发送邮箱验证码
 * @param {?email=2968712478@qq.com} data   传入的是个对象 
 * @returns 
 */
export const sendEmailValidate = (data) => {
    return request({
        url: '/sendEmailValidate',
        method: 'POST',
        params: data
    })
}


/**注册账号页面
 * 手机号码唯一性验证
 * @param {?phonenumber=15698546567&name=15698546567} data 
 * @returns 
 */
export const checkPhoneUnique = (data) => {
    return request({
        url: '/system/user/checkPhoneUnique',
        method: 'POST',
        params: data
    })
}

/**注册账号页面
 * 提交注册
 * @param {?email=2968712478@qq.com&userName=%E6%9D%8E%E5%9B%9B&emailValidateCode=4am2b&sex=1&password=88888&password2=88888&phonenumber=13564854654&idCardPhoto=2841} data 
 * @returns 
 */
export const register = (data) => {
    return request({
        url: '/system/user/register',
        method: 'POST',
        params: data
    })
}

/**忘记密码页面
 * 发送邮箱验证码
 * @param {?email=296956129@qq.com} data 
 * @returns 
 */
export const sendEmailForgetValidate = (data) => {
    return request({
        url: '/sendEmailForgetValidate',
        method: 'POST',
        params: data
    })
}

/**忘记密码页面
 * 校验验证码
 * @param {?email=296956129@qq.com&emailValidateCode=b3mnb} data 
 * @returns 
 */
export const checkEmailForgetValidateCode = (data) => {
    return request({
        url: '/system/user/checkEmailForgetValidateCode',
        method: 'GET',
        params: data
    })
}

/**忘记密码页面
 * 提交密码重置
 * @param {?email=2968712478@qq.com&emailValidateCode=b3mnb&password=88888&confirm=88888} data 
 * @returns 
 */
export const forgetPwd = (data) => {
    return request({
        url: '/system/user/forgetPwd',
        method: 'POST',
        params: data
    })
}


/**登录
 * 登录
 * @param {?username=admin&password=88888&validateCode=1&rememberMe=false} data 
 * @returns 
 */
export const login = (data) => {
    return request({
        url: '/login2GetUser',
        method: 'POST',
        params: data
    })
}

/**修改密码
 * 修改密码
 * @param {?userId=671&loginName=test&password=88888&password2=258369&password3=258369} data 
 * @returns 
 */
export const resetPwd = (data) => {
    return request({
        url: '/system/user/resetPwd',
        method: 'POST',
        params: data
    })
}

/**
 * 微信绑定用户
 * 
 * @param {*} data 
 * @returns 
 */
export const bindUserInfo = (data) => {
    return request({
        url: '/bindUserInfo',
        method: 'POST',
        params: data
    })
}

