// 每个封装请求函数的文件都与代理名字相同 config/index

import * as applicationManage from './modules/applicationManage'
import * as baseFunction from './modules/baseFunction'
import * as homePage from './modules/homePage'
import * as questionManage from './modules/questionManage'
import * as studentDocument from './modules/studentDocument'
import * as systemManage from './modules/systemManage'

export default {
    applicationManage,
    baseFunction,
    homePage,
    systemManage,
    questionManage,
    studentDocument,
}